.navbar {
  min-height: 56px;
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  
  .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: $primary;
    border: 1px solid $primary;
    &:focus {
      outline: none;
    }
  }
  .navbar-brand {
    font-weight: 700;
    color: fade-out($white, .5);
    padding: .9rem 0;
  }
  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }


    .nav-link-foo {
      transition: none;
      padding: 2rem 1.5rem;
      color: fade-out($white, .5);
      &:hover {
        color: fade-out($white, .25);
      }
      &:active {
        color: white;
      }
    }
    &.navbar-shrink {
      background-color: $white;
      .navbar-brand {
        color: $black;
      }
      
    }
  
}
