@import 'scss/grayscale.scss';

// Bootstrap

// // Required
// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";

// // Optional
// @import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/grid";

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome.scss";
